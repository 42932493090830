import { Divider as AntDivider, DividerProps as AntDividerProps } from 'antd';

export interface DividerProps extends AntDividerProps {
  marginless?: boolean;
}

export const Divider = (props: DividerProps) => {
  const { marginless = false, ...rest } = props;
  return <AntDivider style={{ ...(marginless && { margin: '0px' }) }} {...rest} />;
};
